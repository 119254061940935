<template>
  <div>
    <el-table style="width: 100%" :data="teamList" border>
      <el-table-column
        prop="timer"
        label="报名日期"
        width="200"
        :formatter="formatterTimer"
      >
      </el-table-column>
      <el-table-column prop="name" label="战队名" width="200">
      </el-table-column>
      <el-table-column prop="piaoshu" label="获赞数" width="150">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="auto">
        <template slot-scope="scope">
          <el-button @click="check(scope.row)" type="primary" size="small"
            >检查</el-button
          >
          <el-button type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="currentPage"
      :page-size="10"
      @current-change="handleCurrentChange"
      layout="prev, pager, next, jumper"
      :total="1000"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getEnrollList } from "@/api/enroll/index";
export default {
  name: "AllTeam",
  mounted() {
    this.initData(1,10);
  },
  data() {
    return {
      teamList: [],
      currentPage:1
    };
  },
  methods: {
    initData() {
      getEnrollList(this.currentPage,10)
        .then((res) => {
          console.log(res.data);
          this.teamList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //格式化日期
    formatterTimer(row, column, cellValue, index) {
      let rec;
      if (index < 4) {
        rec = "直邀队伍";
      } else {
        let year = cellValue.slice(0, 4);
        let month = cellValue.slice(5, 7);
        let day = cellValue.slice(8, 10);
        let time = cellValue.slice(11, 19);
        rec = year + "年" + month + "月" + day + "日" + " " + time;
      }
      return rec;
    },
    //检查事件
    check(row) {
      console.log(row);
      this.$store.commit("updateTeamName", row.name);
      this.$emit("searchteam");
    },
    handleCurrentChange(val) {
      this.initGetUsers(val,10);
    },
  },
};
</script>

<style scoped lang="less"></style>
